import { Container, Section } from 'components/homepage';

import type { MediaCoverage } from 'types';

// MediaCoverage
import FastCoLogo from 'public/assets/home-page/mediacoverage/FastCoLogo.svg';
import MHLogo from 'public/assets/home-page/mediacoverage/MHLogo.svg';
import NYTLogo from 'public/assets/home-page/mediacoverage/NYTLogo.svg';
import OutsideLogo from 'public/assets/home-page/mediacoverage/OutsideLogo.svg';
import TheVergeLogo from 'public/assets/home-page/mediacoverage/TheVergeLogo.svg';
import WSJLogo from 'public/assets/home-page/mediacoverage/WSJLogo.svg';

const mediaCoverageData = {
  list: [
    {
      image: NYTLogo,
      link: 'https://www.nytimes.com/2021/02/08/well/diet-glucose-monitor.html',
    },
    {
      image: WSJLogo,
      link: 'https://www.wsj.com/articles/how-health-and-fitness-trackers-are-about-to-get-a-lot-more-granular-11641999617',
    },
    {
      image: MHLogo,
      link: 'https://www.menshealth.com/health/a35541222/cgm-continual-glucose-monitor-levels-supersapiens/',
    },
    {
      image: OutsideLogo,
      link: 'https://www.outsideonline.com/outdoor-gear/tools/best-new-fitness-trackers-2022/',
    },
    {
      image: FastCoLogo,
      link: 'https://www.fastcompany.com/90741642/this-former-spacex-engineer-wants-to-prevent-you-from-getting-diabetes',
    },
    {
      image: TheVergeLogo,
      link: 'https://www.theverge.com/22794594/levels-health-glucose-fitness-tracker-review',
    },
  ],
};

/**
 * @summary Renders a content block with links to external media coverage.
 * @returns {JSX.Element}
 */

const MediaCoverage = (): JSX.Element => {
  const { list } = mediaCoverageData;
  return (
    <Section style={{ backgroundColor: 'rgb(15, 15, 15)' }}>
      <style jsx>{`
        .media-links {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-around;
          align-items: center;
          height: 160px;
          width: 100%;
        }
        .image-container {
          display: flex;
          padding: 0px 10px;
          flex-basis: 16.66%;
        }
        @media (max-width: 900px) {
          .image-container {
            flex-basis: 33.33%;
          }
        }
        @media (max-width: 400px) {
          .media-links {
            height: 160px;
          }
          .image-container {
            padding: 5px 5px;
          }
        }
      `}</style>
      <Container>
        <div className="media-links">
          {list.map(({ image: Image, link }, index) => (
            <div key={`media-${index}`} className={'image-container'}>
              <a href={link} key={index} rel="noreferrer" target={'_blank'}>
                <Image />
              </a>
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default MediaCoverage;
