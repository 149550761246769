import Image from 'next/image';
import Ratings from 'react-ratings-declarative';

import { Container, Section } from 'components/homepage';
import Title from 'components/texts/Title';

import type { UserReviews } from 'types';

/**
 * @sumary Renders a content block with user reviews of the mobile app.
 * @returns {JSX.Element}
 */

const UserReviews = (): JSX.Element => {
  return (
    <>
      <style jsx>{`
        .member-stories-button:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
        .one-ceo-title {
          font-size: 2em;
          padding-bottom: 30px;
          font-weight: 300;
        }
        .betsy-image {
          display: flex;
          flex-direction: column;
          width: 50%;
          position: relative;
          height: 400;
        }
        .youtube-container {
          display: flex;
          flex-direction: row;
          padding-bottom: 80px;
          align-items: center;
          justify-content: space-around;
          width: 100%;
        }
        .youtube-player-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          flex-grow: 1;
          margin: 0 10px;
          height: 300px;
        }
        @media (max-width: 1100px) {
          .betsy-image {
            display: none;
          }
        }
        @media (max-width: 900px) {
          .one-ceo-title {
            font-size: 1.5em;
          }
          .youtube-container {
            flex-direction: column;
          }
          .youtube-player-container {
            width: 500px;
            margin-bottom: 15px;
          }
        }
        @media (max-width: 600px) {
          .youtube-player-container {
            width: 80vw;
            height: 200px;
          }
        }
      `}</style>

      <Section style={{ backgroundColor: 'rgb(15, 15, 15)' }} center>
        <div style={{ width: '100%', padding: '50px 0', background: 'linear-gradient(to top right, #1d7d6c, #05473c)' }}>
          {/* feel out "layer behind" and "banner across" */}
          <Container>
            <Title size={'h2'} style={{ fontWeight: 300 }}>
              Join <span style={{ borderBottom: '2px white solid' }}>50,000+</span> members achieving real results
            </Title>
          </Container>
        </div>
        <Container>
          <div style={{ display: 'flex', flexDirection: 'row', paddingBottom: 50 }}>
            <div className="betsy-image">
              <Image layout="fill" objectFit="scale-down" src={'/assets/home-page/user-reviews/betsy-3.png'} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: 50 }}>
              <div className="one-ceo-title">One CEO's 81 pound weight loss using glucose monitoring (Betsy McLaughlin & Casey Means)</div>
              <iframe
                allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
                frameBorder="0"
                height="175"
                style={{ width: '100%', maxWidth: 660, overflow: 'hidden', borderRadius: 10 }}
                sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
                src="https://embed.podcasts.apple.com/us/podcast/43-one-ceos-81-pound-weight-loss-using-a-levels-cgm/id1563263076?i=1000542334194"
              ></iframe>
              <a
                style={{ textDecoration: 'underline', marginTop: 15, fontWeight: 300, fontSize: '1.2em' }}
                href="https://podcasts.apple.com/us/podcast/levels-a-whole-new-level/id1563263076"
                target="_blank"
                rel="noreferrer"
              >
                See more podcasts by Levels.
              </a>
            </div>
          </div>

          <div className="youtube-container">
            <div className="youtube-player-container">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/DhE_8BO60mI?list=PLt_J9-Sfer-kZIGDbK50PSJgGbgk5AuFc"
                title="Fitness Competitor’s IVF JOURNEY &amp; What Real Health Looks Like | Sara Beemer Levels Member Story"
              />
            </div>
            <div className="youtube-player-container">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/Sy4Ob4Y0c2Y?list=PLt_J9-Sfer-kZIGDbK50PSJgGbgk5AuFc"
                title="How Chef Paul Canales&#39; Journey with CGMs Changed the Way He Thinks About Food | Levels Member Story"
              />
            </div>
            <div className="youtube-player-container">
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/Y8BxkbzeF-E?list=PLt_J9-Sfer-kZIGDbK50PSJgGbgk5AuFc"
                title="Curiosity Led Natalie to Monitoring Her Glucose | Levels Member Story with Natalie Lumpkin"
              />
            </div>
          </div>

          {/* TODO create a dedicated page for this */}
          {/* <div style={{paddingBottom: 100, paddingTop: 50}}>
            <a href="#" className="member-stories-button" style={{transition: "background-color 0.3s ease", borderWidth: 1, borderColor: '#ffffff', borderRadius: 5, padding: "15px 30px", fontSize: '1.2em'}} >
              See more member stories
            </a>
          </div> */}

          {/* Apple Badge */}
          <a href="https://apps.apple.com/us/app/levels-metabolic-health/id1481511675" target="_blank" rel="noreferrer">
            <div className={'mb-10 sm:by-14 md:mb-20'}>
              <div className={'hidden sm:flex flex-row items-center'}>
                <hr className={'grow text-disabled-dark w-full'} />

                <div className={'shrink-0 mx-6 relative'}>
                  <div className={'absolute text-center w-full top-[21px]'}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {/* text for "4.x stars out of 5" - not currently using */}
                      {/* <span style={{fontSize: '1.2em'}}>{text[0]}</span> <br /> */}
                      <Ratings
                        rating={4.6}
                        widgetDimensions="25px"
                        widgetSpacings="3px"
                        widgetRatedColors="rgb(230, 151, 62)"
                        widgetEmptyColors="rgb(185, 185, 185)"
                      >
                        {Array(5)
                          .fill(0)
                          .map((_, index) => (
                            <Ratings.Widget key={`rating-${index}`} />
                          ))}
                      </Ratings>
                      <span style={{ paddingTop: 5 }} className={'text-disabled-dark'}>
                        from 2,500+ reviews
                      </span>
                    </div>
                  </div>
                  <Image src={'/assets/home-page/Apple-crown.svg'} height={'120'} width={'240'} />
                </div>

                <hr className={'grow text-disabled-dark w-full'} />
              </div>
            </div>
          </a>
        </Container>
      </Section>
    </>
  );
};

export default UserReviews;
