import Image from 'next/image';

/**
 * @summary Renders a content block with a callout.
 * @param {string} [type]
 * @returns {JSX.Element}
 */

const investorList = [
  {
    href: 'https://a16z.com/',
    image: '/assets/footer/a16zLogo.svg',
    name: 'a16z',
  },
  {
    href: 'http://loupventures.com/',
    image: '/assets/footer/LoupVenturesLogo.svg',
    name: 'Loup Ventures',
  },
  {
    href: 'https://www.shrug.vc/',
    image: '/assets/footer/ShrugCapitalLogo.svg',
    name: 'Shrug Capital',
  },
  {
    href: 'https://www.avgbasecamp.com/',
    image: '/assets/footer/BasecampFundLogo.svg',
    name: 'Basecamp Fund',
  },
  {
    href: 'https://www.foundercollective.com/',
    image: '/assets/footer/FounderCollectiveLogo.svg',
    name: 'Founder Collective',
  },
];

const Investors = (): JSX.Element => {
  return (
    <section
      style={{
        background: 'linear-gradient(to bottom right, rgb(15, 15, 15), rgb(40, 40, 40))',
        minHeight: 350,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <style jsx>{`
        .title {
          text-align: center;
          color: white;
          font-size: 2em;
          padding-bottom: 30px;
          margin-top: 30px;
        }
        .investor-logo-container {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          justify-content: center;
          max-width: 1200px;
        }
        .investor-logo-wrapper {
          display: flex;
          flex-basis: 20%;
          justify-content: center;
          align-items: center;
          padding: 0px 10px;
        }
        .investor-logo {
          height: 100px;
          width: 150px;
          position: relative;
          max-width: 30vw;
        }
        .read-more {
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          width: 100%;
          padding-top: 30px;
          padding-bottom: 30px;
        }
        @media (max-width: 1100px) {
          .investor-logo-wrapper {
            flex-basis: 33.33%;
          }
        }
        @media (max-width: 900px) {
          .investor-logo-container {
            width: 90vw;
          }
          .read-more {
            flex-direction: column;
            line-height: 4em;
            text-align: center;
            font-size: 0.8em;
          }
        }
      `}</style>
      <div className="title">Backed by the best investors in the business</div>
      <div className="investor-logo-container">
        {investorList.map(item => {
          return (
            <div key={`${item.href}`} className="investor-logo-wrapper">
              <div className="investor-logo">
                <a href={item.href} title={item.name}>
                  {/* TODO figure out why there are weird image artifacts on the top and bottom of these images */}
                  <Image layout="fill" objectFit="contain" src={item.image} />
                </a>
              </div>
            </div>
          );
        })}
      </div>
      <div className="read-more">
        <a
          style={{ fontSize: '1.5em', textDecoration: 'underline', color: '#54ECCA' }}
          href="https://www.levelshealth.com/blog/the-secret-levels-master-plan-just-between-you-and-me-cgm-glucose"
          target="_blank"
          rel="noreferrer"
        >
          Read the Secret Levels Master Plan
        </a>
        <a
          style={{ fontSize: '1.5em', textDecoration: 'underline', color: '#54ECCA' }}
          href="https://app.levelshealth.com/investor-updates"
          target="_blank"
          rel="noreferrer"
        >
          View historic investor updates
        </a>
      </div>
    </section>
  );
};

export default Investors;
