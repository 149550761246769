import { useEffect, useState } from 'react';

import cx from 'classnames';

import { useGetStartedButton, US_CTA } from 'components/buttons/GetStartedButton';
import PrimaryButtonV2 from 'components/buttons/PrimaryButtonV2';
import posthog from 'posthog-js';
import { POSTHOG_EVENT_NAME } from 'util/frontendPosthog';

interface HomePagePromoProps {
  primaryColor?: string;
  secondaryColor?: string;
  buttonText?: string;
  content?: string;
  className?: string;
}

/**
 * Simple function to convert markdown-like syntax to react elements
 * (Only handles * and _)
 * @param content
 */
function simpleFormat(content: string) {
  return content.split('*').map((line, index) => {
    if (index % 2 === 0) {
      return line.split('_').map((_line, _index) => {
        if (_index % 2 === 0) {
          return <span key={_index}>{_line}</span>;
        }

        return (
          <span className={'italic'} key={`_-${_index}`}>
            {_line}
          </span>
        );
      });
    } else {
      return <strong key={`*-${index}`}>{line}</strong>;
    }
  });
}

function HomepagePromo({ className = '' }: HomePagePromoProps) {
  const { url } = useGetStartedButton();
  const [action, setAction] = useState<string | undefined>(undefined);

  useEffect(() => {
    setAction(url);
  }, [action, url]);

  const { primaryColor, secondaryColor } = {
    primaryColor: '#145348',
    secondaryColor: '#ADECE1',
  };

  return (
    <div
      className={`py-4 px-4 rounded-2xl flex flex-col sm:flex-row gap-4 justify-center items-center ${className}`}
      style={{
        backgroundColor: secondaryColor,
        color: primaryColor,
      }}
    >
      <span>{simpleFormat('*Now available: *Next generation hardware is here – _smaller, faster, and more accurate_.')}</span>
      <form
        action={action}
        method="GET"
        className="w-full md:w-auto"
        onSubmit={e => {
          const data = Object.fromEntries(new FormData(e.target as HTMLFormElement));
          posthog.capture(POSTHOG_EVENT_NAME.EMAIL_CAPTURED, { from: 'homepage-inline-cta', $set: { email: data.email } });
          url === US_CTA.url && posthog.capture(POSTHOG_EVENT_NAME.HOMEPAGE_SIGNUP_CTA_CLICK);
        }}
      >
        <div className="space-y-3 lg:space-x-3 lg:space-y-0">
          <input
            type="email"
            name="email"
            className="w-full lg:w-auto rounded-md border-1 border-green-800 h-8 text-black"
            placeholder="email address"
            required
          />
          <PrimaryButtonV2
            className={'order-now-button w-full lg:w-auto'}
            style={{
              paddingTop: 8,
              paddingBottom: 8,
              paddingRight: 16,
              paddingLeft: 16,
              height: 'auto',
              color: secondaryColor,
              backgroundColor: primaryColor,
            }}
            type="submit"
            variant={'secondary'}
          >
            Order Now
          </PrimaryButtonV2>
        </div>
      </form>
    </div>
  );
}

export default function ConnectedSignUpPromo() {
  const [animateIn, setAnimateIn] = useState(false);

  useEffect(() => {
    setAnimateIn(true);
  }, []);

  return (
    <HomepagePromo
      className={cx('transition-opacity duration-700', {
        'opacity-0': !animateIn,
        'opacity-100': animateIn,
      })}
    />
  );
}
