// implementing designs from here:
// https://www.figma.com/file/hWRtXtcn7REoPb67iMOUog/Levels-App?type=design&node-id=852-308&mode=design&t=wCEazJeUYQ9ACZpI-0
// related to this ticket:
// https://www.notion.so/levelshealth/Levels-Website-v3-1-cbd72a4ee43c4ef0864ad5847efc12f8

import Image from 'next/image';
import { useEffect, useState } from 'react';

const activeSelectorData = [
  {
    title: 'Sign up to purchase your CGM',
    description: 'After receiving your sensor, download the Levels app to connect it and get real-time glucose data.',
    titleColor: '#ADECE1',
    descriptionColor: 'white',
    backgroundColor: '#05473C',
    imageUrl: '/assets/how-it-works/how-it-works-1.png',
    appImageUrl: '/assets/how-it-works/app-1.png',
  },
  {
    title: 'Log food and lifestyle to track your glucose',
    description: 'Track your food and physical activities to receive tailored advice on glucose management and overall health.',
    titleColor: 'rgb(219, 174, 74)',
    descriptionColor: 'rgb(233, 214, 168)',
    backgroundColor: 'rgb(59, 56, 51)',
    imageUrl: '/assets/how-it-works/how-it-works-2.png',
    appImageUrl: '/assets/how-it-works/app-2.png',
  },
  {
    title: 'Compare results over time to meet your goals',
    description: 'Track daily activities to manage glucose levels, identify trends, and meet your health goals.',
    titleColor: 'rgb(113, 190, 235)',
    descriptionColor: 'rgb(186, 222, 240)',
    backgroundColor: 'rgb(40, 51, 58)',
    imageUrl: '/assets/how-it-works/how-it-works-3.png',
    appImageUrl: '/assets/how-it-works/app-3.png',
  },
];

const HowItWorks = () => {
  const [activeSelectorIndex, setActiveSelectorIndex] = useState(0);
  const [autoToggle, setAutoToggle] = useState(true);

  useEffect(() => {
    if (autoToggle) {
      const interval = setInterval(() => {
        setActiveSelectorIndex(prevIndex => (prevIndex + 1) % activeSelectorData.length);
      }, 3000);
      return () => clearInterval(interval);
    }
    return;
  }, [autoToggle]);

  const handleSelectorClick = index => {
    setAutoToggle(false); // Stop auto toggling
    setActiveSelectorIndex(index);
  };

  return (
    <>
      <style jsx>{`
        .section {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          background-color: #13171b;
          padding-bottom: 50px;
        }
        .container {
          width: 1200px;
          display: flex;
          flex-direction: column;
        }
        .selector-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
        .selector-column {
          display: flex;
          flex-direction: column;
          width: 400px;
          flex-shrink: 0;
        }
        .image-container {
          position: relative;
          height: 500px;
          width: 600px;
          display: flex;
          margin: 0px 20px;
        }
        .selector-item-disabled {
          display: flex;
          flex-direction: column;
          padding: 15px 32px;
          border-radius: 10px;
          background-color: rgb(45, 48, 50);
          cursor: pointer;
        }
        .selector-item-disabled:not(:first-child) {
          margin-top: 20px;
        }
        .app-container {
          position: relative;
          height: 500px;
          width: 267px;
        }
        .title {
          font-size: 56px;
          color: white;
          margin-top: 50px;
          margin-bottom: 20px;
        }
        .subtitle {
          font-size: 24px;
          color: rgba(255, 255, 255, 0.8);
          width: 720px;
          line-height: 28px;
          margin-bottom: 50px;
        }
        .item-title-row {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        .item-index {
          font-size: 56px;
          padding-right: 20px;
          color: rgb(85, 87, 88);
        }
        .item-title {
          font-size: 24px;
          line-height: 28px;
          color: rgb(242, 242, 242);
        }
        .item-description {
          font-size: 18px;
          overflow: hidden;
          height: auto;
          max-height: 0;
        }
        .item-description-open {
          max-height: 200px;
          padding-top: 10px;
        }
        @media (max-width: 1300px) {
          .image-container {
            display: none;
          }
          .title {
            text-align: center;
          }
          .subtitle {
            text-align: center;
            align-self: center;
          }
          .container {
            width: 800px;
          }
        }
        @media (max-width: 800px) {
          .app-container {
            display: none;
          }
          .container {
            align-items: center;
            width: 100%;
          }
          .subtitle {
            display: none;
          }
          .selector-row {
            justify-content: center;
          }
          .selector-column {
            width: 90%;
          }
          .title {
            font-size: 42px;
          }
        }
      `}</style>
      <div className="section">
        <div className="container">
          <h1 className="title">How It Works</h1>
          <h2 className="subtitle">
            Levels shows you how food and other activites affect your blood glucose levels so you can see what's working for you, and how to
            reach your goals. Get to know your body in 3 easy steps.
          </h2>
          <div className="selector-row">
            <div className="selector-column">
              {activeSelectorData.map((item, index) => {
                const active = activeSelectorIndex === index;
                return (
                  <div
                    onClick={() => handleSelectorClick(index)}
                    key={`${item.title}`}
                    className="selector-item-disabled"
                    style={{ backgroundColor: active ? item.backgroundColor : '' }}
                  >
                    <div className="item-title-row">
                      <div className="item-index" style={{ color: active ? item.titleColor : '' }}>
                        {index + 1}
                      </div>
                      <div className="item-title" style={{ color: active ? item.titleColor : '' }}>
                        {item.title}
                      </div>
                    </div>
                    <div
                      className={`item-description ${active ? 'item-description-open' : ''}`}
                      style={{ color: active ? item.descriptionColor : '' }}
                    >
                      {item.description}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="image-container">
              <Image src={activeSelectorData[activeSelectorIndex].imageUrl} layout="fill" objectFit="contain" quality={100} />
            </div>
            <div className="app-container">
              <Image src={activeSelectorData[activeSelectorIndex].appImageUrl} layout="fill" objectFit="contain" quality={100} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HowItWorks;
