import Image from 'next/image';
import useNextBlurhash from 'use-next-blurhash';

import { Section } from 'components/homepage';

import ConnectedSignUpPromo from './HomePagePromo';

const heroData = {
  subHeader: 'Learn how food affects your health with a continuous glucose monitor (CGM).',
  title: <>Your health, measured.</>,
};

/**
 * @summary Renders a content block with video and text content.
 * @returns {JSX.Element}
 */

const HeroArea = (): JSX.Element => {
  const { subHeader, title } = heroData;

  const heroSource = 'woman-boxer-hero.jpg';

  /**
   * @summary creates a compact blurred image placeholder from a string
   * @see {@link https://github.com/woltapp/blurhash}
   * @see {@link https://woltapp.github.io/react-blurhash/}
   */
  const blurhash = 'q24-z.bH0LV[^jxtR*IW={fj9uf7-Va{I:sp0zWB={xtR+E2Ip?GE3R*-U%1NaIoaext-VayM|azS4xZ$%Ipxts:RjIpNa-U-UIp';

  const [blurDataUrl] = useNextBlurhash(blurhash);

  const opacity = 1;

  return (
    <>
      <style jsx>{`
        .hero-title {
          font-family: 'GTSectraFine';
          text-align: center;
          font-size: 4em;
          line-height: 1em;
          margin-bottom: 30px;
          padding-top: 50px;
        }
        .hero-subheading {
          text-align: center;
          font-weight: 300;
          font-size: 1.5em;
          margin-bottom: 30px;
        }
        @media (max-width: 700px) {
          .hero-title {
            padding-top: 150px;
          }
        }
        @media (max-width: 400px) {
          .hero-title {
            font-size: 3em;
          }
          .hero-subheading {
            font-size: 1.2em;
          }
        }
      `}</style>

      <Section className="h-screen w-screen relative" style={{ backgroundColor: 'rgb(15, 15, 15)', maxHeight: 800 }}>
        <Image
          blurDataURL={blurDataUrl}
          src={`/assets/home-page/hero/${heroSource}`}
          layout="fill"
          objectFit="cover"
          objectPosition={'top'}
          placeholder="blur"
          priority
          quality={100}
          style={{
            opacity,
          }}
        />
        <div
          style={{
            position: 'absolute',
            left: '0',
            right: '0',
            bottom: '0',
            height: '100px',
            background: 'linear-gradient(to bottom, transparent, rgb(15, 15, 15))',
          }}
        />
        <div
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
          className="flex flex-col items-center justify-center h-full px-6 py-20"
        >
          <div className="hero-title">{title}</div>
          <div className="hero-subheading">{subHeader}</div>
          <ConnectedSignUpPromo />
        </div>
      </Section>
    </>
  );
};

export default HeroArea;
