import 'keen-slider/keen-slider.min.css';
import { useKeenSlider } from 'keen-slider/react';
import Image from 'next/image';

// Social Media
import ApplePodcastsLogo from 'public/assets/footer/ApplePodcastsLogo.svg';
import FacebookLogo from 'public/assets/footer/FacebookLogo.svg';
import InstagramLogo from 'public/assets/footer/InstagramLogo.svg';
import LinkedInLogo from 'public/assets/footer/LinkedInLogo.svg';
import XLogo from 'public/assets/footer/XLogo.svg';
import YoutubeLogo from 'public/assets/footer/YoutubeLogo.svg';

const instagramAnimation = { duration: 30000, easing: (t: number) => t };

const instagramSlides = [
  {
    image: '/assets/home-page/social/instagram/instagram-1.png',
    link: 'https://www.instagram.com/thestephaniecrane/',
  },
  {
    image: '/assets/home-page/social/instagram/instagram-2.png',
    link: 'https://www.instagram.com/shannonmichelefit/',
  },
  {
    image: '/assets/home-page/social/instagram/instagram-3.png',
    link: 'https://www.instagram.com/abbyrosegreen/',
  },
  {
    image: '/assets/home-page/social/instagram/instagram-4.png',
    link: 'https://www.instagram.com/ginahaeussnermd/',
  },
  {
    image: '/assets/home-page/social/instagram/instagram-5.png',
    link: 'https://www.instagram.com/stephgreunke/',
  },
  {
    image: '/assets/home-page/social/instagram/instagram-6.png',
    link: 'https://www.instagram.com/chefshirleytemple/',
  },
];

// NOTE from Sam: Whenever I see things like this, the first thing I always check is "how old are these posts?"
// If the posts are super old, they lose credibility. The fresher they are, the more they gain credibility.
// We need to create a process to keep these fresh.

// pulled from https://www.notion.so/levelshealth/990b23e2c7c54108a1f75931bc21915e?v=41189f7d9e294111b3ca74ce5fd87b95
// add anything with "featured" tag
// updated monthly by ???
// instagram is highly visual and very alive... we should lean into this
const InstagramCarousel = () => {
  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    renderMode: 'performance',
    drag: false,
    breakpoints: {
      '(max-width: 1500px)': {
        slides: {
          perView: 4,
        },
      },
      '(max-width: 1200px)': {
        slides: {
          perView: 3,
        },
      },
      '(max-width: 800px)': {
        slides: {
          perView: 2,
        },
      },
      '(max-width: 500px)': {
        slides: {
          perView: 1,
        },
      },
    },
    slides: {
      perView: 5,
    },
    created(s) {
      s.moveToIdx(5, true, instagramAnimation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, instagramAnimation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, instagramAnimation);
    },
  });

  return (
    <>
      <style jsx>{`
        .instagram-slider {
          height: 500px;
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          overflow: hidden;
        }
      `}</style>
      <div ref={sliderRef} className="keen-slider instagram-slider">
        {instagramSlides.map(insta => (
          // because tweets come in all shapes and sizes, we need to overflow and fade to bottom.
          <div className="keen-slider__slide" style={{ height: 500, padding: '0 10px' }} key={insta.image}>
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <a href={insta.link} target="_blank" rel="noreferrer">
                <Image layout="fill" objectFit="scale-down" objectPosition="bottom" src={insta.image} />
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

// the purpose of this section is to show that there are (real) cool people on the internet who like Levels
// this adds to social proof and also give a feeling that people are participating in something larger than themselves
// it makes it feel more "alive"
// and it nudges people to post things on social themselves

// pulled from https://www.notion.so/levelshealth/4a97bb2d55d54b6a84548215d87b5905?v=4686cbd99b7a4871a919974bf99bb71c
// that's where we keep track of the "featured" tweets
// updated monthly by ???
const twitterSlides = [
  {
    image: '/assets/home-page/social/twitter/twitter-1.png',
    link: 'https://twitter.com/vibhor_chhabra/status/1666303991188426754',
  },
  {
    image: '/assets/home-page/social/twitter/twitter-2.png',
    link: 'https://twitter.com/jstorres/status/1649069585310810112',
  },
  {
    image: '/assets/home-page/social/twitter/twitter-3.png',
    link: 'https://twitter.com/nuria_sm/status/1646339602062667776',
  },
  {
    image: '/assets/home-page/social/twitter/twitter-4.png',
    link: 'https://twitter.com/Michael81160403/status/1643583101312835584',
  },
  {
    image: '/assets/home-page/social/twitter/twitter-5.png',
    link: 'https://twitter.com/ChadGraue/statuses/1621704982931062785',
  },
  {
    image: '/assets/home-page/social/twitter/twitter-6.png',
    link: 'https://twitter.com/tulioq/statuses/1615922341749612546',
  },
  {
    image: '/assets/home-page/social/twitter/twitter-10.png',
    link: 'https://twitter.com/arudansk/status/1653513593135005698',
  },
];

const twitterAnimation = { duration: 25000, easing: (t: number) => t };

const TwitterCarousel = () => {
  const [sliderRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    renderMode: 'performance',
    drag: false,
    slides: {
      perView: 3,
    },
    breakpoints: {
      '(max-width: 1200px)': {
        slides: {
          perView: 2,
        },
      },
      '(max-width: 600px)': {
        slides: {
          perView: 1,
        },
      },
    },
    created(s) {
      s.moveToIdx(5, true, twitterAnimation);
    },
    updated(s) {
      s.moveToIdx(s.track.details.abs + 5, true, twitterAnimation);
    },
    animationEnded(s) {
      s.moveToIdx(s.track.details.abs + 5, true, twitterAnimation);
    },
  });

  return (
    // TODO figure out how to make the border radius 10 for these images
    <div
      ref={sliderRef}
      className="keen-slider"
      style={{ height: 500, position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', overflow: 'hidden' }}
    >
      {twitterSlides.map(tweet => (
        // because tweets come in all shapes and sizes, we need to overflow and fade to bottom.
        <div className="keen-slider__slide" style={{ height: 1000, padding: '0 20px' }} key={tweet.image}>
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <a href={tweet.link} target="_blank" rel="noreferrer">
              <Image layout="fill" objectFit="scale-down" objectPosition="top" src={tweet.image} />
            </a>
          </div>
        </div>
      ))}
      <div
        style={{
          position: 'absolute',
          left: '0',
          right: '0',
          bottom: '0',
          height: '200px',
          background: 'linear-gradient(to bottom, transparent, rgb(15, 15, 15))',
        }}
      />
    </div>
  );
};

const socialLinks = [
  {
    href: 'https://facebook.com/LevelsHealth',
    image: FacebookLogo,
    name: 'Facebook',
  },
  {
    href: 'https://www.instagram.com/levels',
    image: InstagramLogo,
    name: 'Instagram',
  },
  {
    href: 'https://x.com/levels',
    image: XLogo,
    name: 'Twitter',
  },
  {
    href: 'https://www.youtube.com/@LevelsHealth/',
    image: YoutubeLogo,
    name: 'Youtube',
  },
  {
    href: 'https://podcasts.apple.com/ca/podcast/a-whole-new-level/id1563263076',
    image: ApplePodcastsLogo,
    name: 'Apple Podcasts',
  },
  {
    href: 'https://www.linkedin.com/company/levels-health/',
    image: LinkedInLogo,
    name: 'LinkedIn',
  },
];

const SocialLinks = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', padding: '30px 0' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {socialLinks.map((item, index) => {
          const { image: Image2 } = item;
          return (
            <a href={item.href} target="_blank" rel="noreferrer" key={index}>
              <Image2 fill="white" style={{ transform: 'scale(2)', margin: 20 }} />
            </a>
          );
        })}
      </div>
    </div>
  );
};

const Social = (): JSX.Element => {
  return (
    <div
      style={{
        background: 'linear-gradient(to top right, rgb(15, 15, 15), rgb(40, 40, 40))',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 50,
      }}
    >
      <InstagramCarousel />
      <SocialLinks />
      <TwitterCarousel />
    </div>
  );
};

export default Social;
