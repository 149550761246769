import Advisors from './components/Advisors';
import HeroArea from './components/HeroArea';
import HowItWorks from './components/HowItWorks';
import Investors from './components/Investors';
import Labs from './components/Labs';
import MediaCoverage from './components/MediaCoverage';
import Social from './components/Social';
import UserReviews from './components/UserReviews';
// import Mission from './components/Mission'

/**
 * @summary Renders each individual block of content on the homepage.
 * If you want to add, hide or reorder blocks this is the place to do it.
 * @returns {JSX.Element}
 */

const HomepageContent = (): JSX.Element => {
  return (
    <main className="text-disabled-text-dark w-screen flex flex-col items-center pt-0">
      <HeroArea />
      <MediaCoverage />
      <Advisors />
      <UserReviews />
      {/* TODO add What is a CGM */}
      <HowItWorks />
      <Labs />
      <Social />
      {/* <Mission /> */}
      <Investors />
      {/* TODO FAQ */}
    </main>
  );
};

export default HomepageContent;
